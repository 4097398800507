<template lang="">
  <div>
    <div class="row">
      <div class="col-md-9 d-md-flex d-none">
        <div class="card dveb-white-card title-card">
          <div class="row my-auto">
            <div class="col-8">
              <Steps
                :steps="5"
                :activeStep="2"
                :lastActive="true"
                :stepNames="stepNames"
                size="lg"
              />
            </div>

            <div style="flex: 1">
              <div class="d-flex px-2">
                <router-link
                  :to="`/properties/find/detail/1`"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <b-button
                    variant="light"
                    class="mr-1"
                    :href="href"
                    @click="navigate"
                    ><i class="flaticon-home-1 text-warning p-0"></i
                  ></b-button>
                </router-link>

                <router-link
                  :to="`declaration/detail`"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <b-button
                    variant="light"
                    class="mr-1"
                    :href="href"
                    @click="navigate"
                    ><i class="flaticon-eye text-success p-0"></i
                  ></b-button>
                </router-link>
              </div>
            </div>
            <div class="row flex-column justfiy-content-center" style="flex: 1">
              <b-button variant="outline-light" class="mx-auto"> 1 </b-button>
              <span
                style="font-size: 0.7rem; font-weight: 300"
                class="text-align-center mx-auto mt-0"
                >İlgili Kişiler</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <Card class="dveb-grayheader-card aside-card">
          <template #header>
            <div class="badges-area">
              <div class="badges">
                <i
                  class="badge"
                  v-for="(badge, key) in badges"
                  :class="[badgesEnum[key]]"
                >
                </i>
              </div>
              <span>TEST BEYAN 05.07.2023</span>
            </div>
          </template>
          <div class="row">
            <div class="col p-0">
              <b-button variant="outline-danger" style="width: 100%">
                <i class="flaticon2-back"></i>
                Geri
              </b-button>
            </div>
            <div class="col p-0 ml-1">
              <b-button variant="outline-primary" style="width: 100%">
                İleri
                <i class="flaticon2-next"></i>
              </b-button>
            </div>
            <div class="col p-0 ml-1">
              <b-button
                variant="outline-light"
                style="width: 100%"
                v-b-toggle.sidebar-right
              >
                <i class="flaticon-edit"></i>
                <span style="font-size: 0.9rem !important">Notlar</span>
              </b-button>
            </div>
          </div>
          <div class="row">
            <b-dropdown variant="light" class="w-100 mt-2 varlik-dropdown">
              <template #button-content> Varlık İşlemleri </template>
              <b-dropdown-item class="w-100"> Çoğalt</b-dropdown-item>
              <b-dropdown-item class="w-100 d-flex"> Sil </b-dropdown-item>
            </b-dropdown>
          </div>

          <div class="row mt-4 d-flex d-md-none">
            <div class="col p-0 ml-1">
              <b-button
                variant="outline-light"
                style="width: 100%"
                v-b-toggle.sidebar-left
              >
                <i class="flaticon-edit"></i>
                <span style="font-size: 0.9rem !important">Varlıklar</span>
              </b-button>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <div class="card dveb-white-card tab-card">
          <b-tabs v-model="tabActive" content-class="mt-3">
            <b-tab title="İlan"><Ilan v-if="tabActive === 0" /></b-tab>
            <b-tab title="Konum"><Konum  v-if="tabActive === 1"/></b-tab>
            <b-tab title="Taşınmaz"><Tasinmaz v-if="tabActive === 2" /></b-tab>
            <b-tab title="İhale Bedelleri"><Bedeller v-if="tabActive === 3" /></b-tab>
            <b-tab title="Fotoğraflar"><Fotograflar v-if="tabActive === 4"/></b-tab>
            <b-tab title="Dosyalar"><Dosyalar v-if="tabActive === 5"/></b-tab>
            <b-tab title="Görüntülenmeler"><Goruntulenmeler v-if="tabActive === 6"/></b-tab>
            <b-tab title="Görüşmeler"><Gorusmeler v-if="tabActive === 7"/></b-tab>
            <b-tab title="Yer Gösterimleri"><YerGosterim v-if="tabActive === 8"/></b-tab>
            <b-tab title="Ön Teklif"><OnTeklif v-if="tabActive === 9"/></b-tab>
            <b-tab title="Teklif İzinleri"><TeklifIzınleri v-if="tabActive === 10"/></b-tab>
            <b-tab title="Teklif Kayıtları"><Kayitlar v-if="tabActive === 11"/></b-tab>
            <b-tab title="Görüşler"><Gorusler v-if="tabActive === 12"/></b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="col-md-3 d-md-flex d-none">
        <div class="card dveb-gray-card aside-gray-card">
          <div class="card-header">ING</div>
          <div class="mt-4">
            <MapView class="map" />
          </div>
          <div class="d-inline-flex mt-4">
            <div class="mx-auto d-inline-flex flex-column">
              <b-button
                v-b-tooltip.hover
                title="Varlık Sahibinin Aynı İldeki Varlıkları"
                variant="light"
              >
                <i class="flaticon-user"></i>
                <span class="badge badge-secondary text-primary ml-4">1</span>
              </b-button>
              <p class="text-bold mt-4">Hatay, ANTAKYA</p>
            </div>
          </div>
          <div class="bg-dveb-light mt-4">
            <p class="text-bold">1 Tapu</p>
          </div>
          <div class="bg-dveb-light mt-4">
            <button class="btn btn-secondary btn-sm mr-4">
              Tapuları Listele
            </button>
            <button class="btn btn-xs">
              <i class="flaticon2-trash text-danger"></i>
              Sil
            </button>
          </div>
          <div class="mt-4 d-flex">
            <div class="circle">R</div>
            <div>
              <h4>NAİL BURAK DUYGULUCAN</h4>
              <h6>Saha Operasyon Uzmanı</h6>
              <div class="d-flex mt-3">
                <p class="mr-4"><span class="font-weight-bold">İş: </span> 0</p>
                <p class="mr-4">
                  <span class="font-weight-bold">Dahili: </span> 0
                </p>
              </div>
              <div class="d-flex">
                <p class="font-weight-bold mr-3">Mail:</p>
                <p>rasim@rasim.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="notes-sidebar">
        <b-sidebar id="sidebar-right" right>
          <div
            class="card dveb-gray-card aside-gray-card"
            style="overflow-y: auto"
          >
            <div class="card-header">
              0 NOT
              <b-button variant="light ml-4" @click="noteModalShow = true"
                >NOT EKLE
              </b-button>
            </div>
            <div
              class="card mt-4 dveb-card-white p-4"
              v-for="(note, key) in notes"
              :key="key"
              style="border-radius: 0 !important"
            >
              <div>
                <h4>{{ note.writer }}</h4>
                <span class="text-bold">{{ note.date }}</span>
                <p class="mt-4">{{ note.note }}</p>
                <hr />
                <div class="row">
                  <b-button
                    variant="danger"
                    class="ml-auto mx-4"
                    @click="openDeleteHandler(key)"
                  >
                    Sil
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </b-sidebar>
        <b-sidebar id="sidebar-left" left>
          <div class="card dveb-gray-card aside-gray-card">
            <div class="card-header">ING</div>
            <div class="mt-4">
              <MapView class="map" />
            </div>
            <div class="d-inline-flex mt-4">
              <div class="mx-auto d-inline-flex flex-column">
                <b-button
                  v-b-tooltip.hover
                  title="Varlık Sahibinin Aynı İldeki Varlıkları"
                  variant="light"
                >
                  <i class="flaticon-user"></i>
                  <span class="badge badge-secondary text-primary ml-4">1</span>
                </b-button>
                <p class="text-bold mt-4">Hatay, ANTAKYA</p>
              </div>
            </div>
            <div class="bg-dveb-light mt-4">
              <p class="text-bold">1 Tapu</p>
            </div>
            <div class="bg-dveb-light mt-4">
              <button class="btn btn-secondary btn-sm mr-4">
                Tapuları Listele
              </button>
              <button class="btn btn-xs">
                <i class="flaticon2-trash text-danger"></i>
                Sil
              </button>
            </div>
          </div>
        </b-sidebar>
      </div>
    </div>
    <b-modal v-model="noteModalShow" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i> Not Ekle</h4>
      </template>
      <div class="form-group">
        <label for="note">Not</label>
        <textarea
          name=""
          v-model="newNote"
          id="note"
          rows="4"
          class="form-control"
        ></textarea>
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="noteModalShow = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="addNewNote" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
    <DeleteOperation />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "declarationDetail",
  components: {
    Steps: () => import("@/components/global/StepBar"),
    MapView: () => import("@/components/dashboard/MapView"),

    Bedeller: () => import("@/components/adverts/DetailTabs/Bedeller"),
    Dosyalar: () => import("@/components/adverts/DetailTabs/Dosyalar"),
    Fotograflar: () => import("@/components/adverts/DetailTabs/Fotograflar"),
    Goruntulenmeler: () =>
      import("@/components/adverts/DetailTabs/Goruntulenmeler"),
    Gorusler: () => import("@/components/adverts/DetailTabs/Gorusler"),

    Gorusmeler: () => import("@/components/adverts/DetailTabs/Gorusmeler"),
    Ilan: () => import("@/components/adverts/DetailTabs/Ilan"),
    Kayitlar: () => import("@/components/adverts/DetailTabs/Kayitlar"),
    Konum: () => import("@/components/adverts/DetailTabs/Konum"),
    OnTeklif: () => import("@/components/adverts/DetailTabs/OnTeklif"),
    Tasinmaz: () => import("@/components/adverts/DetailTabs/Tasinmaz"),

    TeklifIzınleri: () =>
      import("@/components/adverts/DetailTabs/TeklifIzınleri"),
    YerGosterim: () => import("@/components/adverts/DetailTabs/YerGosterim"),
  },
  data() {
    return {
      noteModalShow: false,
      notes: [
        
      ],
      stepNames: ["H:RY", "O:GB", "Y:RY", "İ:RY", "D:GB"],
      newNote: "",
      badges: [1, 2, 3],
      badgesEnum: ["green", "yellow", "red"],
      tabNames: [{ name: "Varlık", component: <EmlakBeyan /> }],
      albumEdit: false,
      tabActive: 0,
    };
  },
  methods: {
    addNewNote() {
      

    },
    openDeleteHandler(key) {
      this.$bvModal.show("modal-deleteOperation");
      let index = this.notes.indexOf(key);
      this.notes.splice(index, 1);
    },
    getNotesList(){
      let payload =`?user_token=${this.myUser}&advert_id=${this.$route.params.id}`
      this.$ApiService.get("advert/note" + payload).then((res)=>{
        this.notes = res.data.response.data
          
      })
    }
  },

  created() {
    this.$watch((this.albumEdit = this.$route.params.albumid ? true : false));

  },
  mounted(){
    this.getNotesList()
  },

  watch: {
    $route(to, from) {
      this.albumEdit = this.$route.params.albumid ? true : false;
    },
    tabActive: function () {
      if (this.$route.params.albumid) {
        const currentParams = this.$router.currentRoute.params;
        this.$router.push(`/properties/find/detail/${currentParams.id}`);
      }
    },
   
  },
  computed:{
    ...mapGetters(["myUser"])
  }
};
</script>
<style lang="scss" scoped>
.card {
  width: 100%;
}
.badges-area {
  display: flex;
  gap: 1rem;
  p {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $dveb-primary !important;
  }
  .badges {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    .badge {
      display: inline-block;
      width: 1rem;
      height: 1rem;

      border-radius: 50%;
      padding: 0 !important;
      &.green {
        background-color: #44b6ae;
      }
      &.yellow {
        background-color: #ffac00;
      }
      &.red {
        background-color: red;
      }
    }
  }
  span {
    font-size: 1rem;
  }
}
.title-card {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.map {
  height: 200px;
}
</style>
